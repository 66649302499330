<template>
  <div class="widget-view-preset">
    <div
      v-for="cell in colors"
      :key="cell.id"
      class="widget-view-preset__cell"
      :style="{ background: cell.color, color: getCellTextColor(cell) }"
    >
      {{ cell.id }}
    </div>
  </div>
</template>

<script>
import fontColorContrast from "font-color-contrast";

export default {
  name: "WidgetViewPreset",
  props: {
    colors: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    getCellTextColor(cell) {
      return fontColorContrast(cell.color);
    },
  },
};
</script>

<style lang="scss" scoped>
.widget-view-preset {
  --cell-size: 25px;

  display: grid;
  grid-template-columns: repeat(3, minmax(0, var(--cell-size)));
  grid-template-rows: repeat(3, minmax(0, var(--cell-size)));
  border-radius: 8px;
  background: $secondary-400;
  border: 1px solid $secondary-400;
  gap: 1px;
  overflow: hidden;

  @media (min-width: $media-tablet) {
    --cell-size: 38px;
  }

  &__cell {
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: $media-tablet) {
      font-size: 14px;
    }
  }
}
</style>
