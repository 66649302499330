<template>
  <Form class="widget-template-form" v-slot="{ invalid, changed }">
    <div class="grid-2 my-24">
      <FormItem v-slot="{ isError }" rules="required">
        <Input
          label="Name"
          placeholder="Name"
          v-model="form.name"
          :isDisabled="isDisabled"
          :isError="isError"
        />
      </FormItem>
      <FormItem v-slot="{ isError }">
        <Select
          label="Product (optional)"
          placeholder="Product"
          v-model="form.productId"
          :options="productOptions"
          :isDisabled="isDisabled"
          :isError="isError"
        />
      </FormItem>
      <FormItem v-slot="{ isError }" rules="required">
        <Select
          label="Widget position"
          placeholder="Position"
          v-model="form.position"
          :options="positionOptions"
          :isDisabled="isDisabled"
          :isError="isError"
        />
      </FormItem>
    </div>
    <FormItem v-slot="{ isError }">
      <Checkbox
        v-if="form.position === WidgetPositionEnum.FLOATING"
        class="mb-24"
        v-model="form.isHideBeacon"
        :isDisabled="isDisabled"
        :isError="isError"
      >
        Hide beacon
      </Checkbox>
    </FormItem>
    <div class="grid-2 my-24">
      <FormItem>
        <Switcher
          v-model="form.isShowCountDownTimer"
          label="Display countdown timer"
        />
      </FormItem>

      <FormItem v-slot="{ isError }">
        <Switcher
          v-model="form.isPromocodesAvailable"
          label="Show promocodes"
          :isDisabled="isDisabled"
          :isError="isError"
        />
      </FormItem>
      <FormItem v-slot="{ isError }">
        <Switcher
          v-model="form.isActive"
          label="Active"
          :isDisabled="isDisabled"
          :isError="isError"
        />
      </FormItem>
      <FormItem v-slot="{ isError }">
        <Switcher
          v-model="form.isDefault"
          label="Default"
          :isDisabled="isDisabled"
          :isError="isError"
        />
      </FormItem>
    </div>
    <div class="widget-template-form__preview-header mb-16">
      <Title :level="2">Preview</Title>
      <Button
        is-small
        is-raised
        @click="$modal.show(widgetViewSettingsModalName)"
      >
        Customize
      </Button>
    </div>
    <iframe
      ref="iframe"
      allow="clipboard-write; payment"
      class="widget-template-form__preview mb-24"
      :class="{ 'm-floating': form.position === WidgetPositionEnum.FLOATING }"
      :src="previewLink"
      v-resize="{
        active: form.position === WidgetPositionEnum.EMBEDDED,
      }"
    />
    <Button
      :is-block="['xs', 'sm'].includes($mq)"
      isOutlined
      @click="openPreviewWindow"
    >
      <Icon name="link" color="primary" />
      Open preview window
    </Button>
    <div
      v-if="triggerLink && form.position === WidgetPositionEnum.FLOATING"
      class="my-24"
    >
      <Input
        class="mb-16 widget-template-form__code"
        isReadonly
        :value="triggerLink"
        label="Trigger link"
      />
      <p class="widget-template-form__code__hint mb-16">
        You can use this link as an href attribute to make any element on your
        page a trigger that will open the widget.
      </p>
      <Button
        :is-block="['xs', 'sm'].includes($mq)"
        isOutlined
        @click="copy(triggerLink, 'Trigger link copied')"
      >
        <Icon name="clipboard" color="primary" />
        Copy trigger link
      </Button>
    </div>
    <div class="my-24">
      <CodeEditor
        class="mb-16 widget-template-form__code"
        :code="previewWidgetCode"
        label="Preview code"
      />
      <p class="widget-template-form__code__hint mb-16">
        Use this code for preview purposes only!
      </p>
      <Button
        :is-block="['xs', 'sm'].includes($mq)"
        isOutlined
        @click="copy(previewWidgetCode, 'Preview code copied')"
      >
        <Icon name="clipboard" color="primary" />
        Copy preview code
      </Button>
    </div>
    <div v-if="widgetCode" class="my-24">
      <CodeEditor
        class="mb-16 widget-template-form__code"
        :code="widgetCode"
        label="Code"
      />
      <Button
        :is-block="['xs', 'sm'].includes($mq)"
        isOutlined
        @click="copy(widgetCode, 'Code copied')"
      >
        <Icon name="clipboard" color="primary" />
        Copy code
      </Button>
    </div>
    <div class="widget-template-form__actions mt-32">
      <Button
        v-if="!isDisabled"
        :is-block="['xs', 'sm'].includes($mq)"
        :isDisabled="invalid || !changed || isDeleting"
        :isLoading="isSubmitting"
        @click="handleSubmit"
      >
        {{ submitButtonText }}
      </Button>
      <Guard permission="affiliates.delete" v-slot="{ isAvailable }">
        <Button
          v-if="isAvailable && isEditMode"
          :is-block="['xs', 'sm'].includes($mq)"
          isOutlined
          variant="danger"
          :isDisabled="isSubmitting"
          :isLoading="isDeleting"
          @click="handleDelete"
        >
          Delete widget template
        </Button>
      </Guard>
    </div>
    <WidgetViewSettingsModal
      :modal-name="widgetViewSettingsModalName"
      :value="form.view"
      :is-submitting="isViewSubmitting"
      :isDisabled="isDisabled"
      @submit="handleViewSubmit"
    />
  </Form>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { WidgetPositionEnum } from "@/helpers/enums";
import alert from "@/plugins/alert";
import { generateWidgetCode, generateWidgetPreviewLink } from "@/helpers/utils";
import WidgetViewSettingsModal from "@/components/widget/WidgetViewSettingsModal.vue";
import cloneDeep from "lodash.clonedeep";
import Guard from "@/components/common/Guard.vue";
import dialog from "@/plugins/dialog";
import CodeEditor from "@/components/common/CodeEditor";
import hljs from "highlight.js/lib/core";
import html from "highlight.js/lib/languages/xml";
hljs.registerLanguage("html", html);

export default {
  name: "WidgetTemplateForm",
  components: { Guard, WidgetViewSettingsModal, CodeEditor },
  props: {
    value: {
      type: Object,
      required: true,
    },
    submitButtonText: {
      type: String,
      default: "Save",
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {},
  data() {
    return {
      form: cloneDeep(this.value),
      positionOptions: [
        {
          name: "Embedded",
          value: WidgetPositionEnum.EMBEDDED,
        },
        {
          name: "Floating",
          value: WidgetPositionEnum.FLOATING,
        },
      ],
      WidgetPositionEnum,
      widgetViewSettingsModalName: "widgetViewSettingsModal",
      isViewSubmitting: false,
      isDeleting: false,
    };
  },
  computed: {
    ...mapState({
      venue: (state) => state.venues.selectedVenue,
      productOptions: (state) => [
        {
          name: "All",
          value: null,
        },
        ...state.products.list.map((item) => ({
          name: `${item.name}${item.isPaused ? " (paused)" : ""}`,
          value: item.id,
          isDisabled: item.isPaused,
        })),
      ],
    }),
    widgetCode() {
      if (this.isEditMode) {
        return generateWidgetCode({
          venueId: this.venue.id,
          widgetTemplateId: this.$route.params.id,
          widgetTheme: this.venue.widget,
        });
      }
      return null;
    },
    previewWidgetCode() {
      return generateWidgetCode({
        venueId: this.venue.id,
        productId: this.form.productId,
        position: this.form.position,
        isPromocodesAvailable: this.form.isPromocodesAvailable,
        widgetTheme: this.venue.widget,
        isHideBeacon: this.form.isHideBeacon,
        view: this.form.view,
      });
    },
    previewLink() {
      return generateWidgetPreviewLink({
        venueId: this.venue.id,
        productId: this.form.productId,
        position: this.form.position,
        isPromocodesAvailable: this.form.isPromocodesAvailable,
        isHideBeacon: this.form.isHideBeacon,
        view: this.form.view,
      });
    },
    triggerLink() {
      if (this.isEditMode) {
        return generateWidgetPreviewLink({
          venueId: this.venue.id,
          widgetTemplateId: this.$route.params.id,
          isAlwaysOpen: true,
        });
      }
      return null;
    },
    isEditMode() {
      return !!this.$route.params.id;
    },
  },
  methods: {
    ...mapActions({
      deleteWidgetTemplate: "widgetTemplates/deleteWidgetTemplate",
      fetchWidgetTemplateById: "widgetTemplates/fetchWidgetTemplateById",
      updateWidgetTemplate: "widgetTemplates/updateWidgetTemplate",
    }),
    goBack() {
      this.$router.push({
        name: "Settings",
      });
    },
    async copy(text, message) {
      await navigator.clipboard.writeText(text);
      alert.open({ message });
    },
    async openPreviewWindow() {
      window.open(this.previewLink, "__blank");
    },
    async handleViewSubmit(view) {
      this.form.view = view;
      this.$modal.hide(this.widgetViewSettingsModalName);
    },
    handleSubmit() {
      this.$emit("submit", this.form);
    },
    async handleDelete() {
      const confirmed = await dialog.confirm({
        title: "Delete Widget Template?",
        message: "All widget template data will be lost",
        okText: "Delete",
        cancelText: "Cancel",
      });
      if (confirmed) {
        try {
          this.isDeleting = true;
          await this.deleteWidgetTemplate(this.$route.params.id);
          await this.$router.push({
            name: "WidgetTemplates",
          });
        } finally {
          this.isDeleting = false;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.widget-template-form {
  &__code {
    ::v-deep input,
    ::v-deep textarea {
      font-family: monospace;
    }

    &__hint {
      font-size: 13px;
      color: $secondary-500;
    }
  }

  &__preview-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 12px;

    @media (min-width: $media-laptop) {
      flex-direction: row;
    }
  }

  &__preview {
    border: 1px solid $secondary-300;
    border-radius: 8px;
    padding: 12px;
    width: 100%;

    &.m-floating {
      height: 150vw !important;

      @media (min-width: $media-tablet) {
        height: 100vw !important;
      }
      @media (min-width: $media-laptop) {
        height: 50vw !important;
      }
      @media (min-width: $media-desktop) {
        height: 42vw !important;
      }
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (min-width: $media-laptop) {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 20px;
    }
  }
}
</style>
