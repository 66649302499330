<template>
  <CustomModal
    :name="modalName"
    isScrollable
    classes="widgetViewSettingsModal"
    width="90%"
    @before-close="reset"
    @before-open="reset"
  >
    <div class="widgetViewSettingsModal__close">
      <Icon name="close" is-clickable color="secondary-500" @click="close" />
    </div>
    <template v-slot:header>
      <Title class="widgetViewSettingsModal__title">
        Widget view settings
      </Title>
    </template>
    <Form class="widgetViewSettingsModal__form" v-slot="{}">
      <Title :level="2" class="mb-24">Appearance</Title>
      <div class="widgetViewSettingsModal__presets mb-32">
        <div
          v-for="(preset, index) in WIDGET_VIEW_COLOR_PRESETS"
          :key="index"
          :class="[
            'widgetViewSettingsModal__presets__item',
            {
              'm-active': isPresetActive(preset),
              'm-disabled': isDisabled,
            },
          ]"
          @click="!isDisabled && acceptPreset(preset)"
        >
          <WidgetViewPreset :colors="getPresetColors(preset)" />
        </div>
      </div>
      <Title :level="2" class="mb-16">Font</Title>
      <FormItem rules="required" class="mb-32">
        <Select
          v-model="innerValue.fontFamily"
          :options="fontOptions"
          label="Font"
          placeholder="Font"
          :isDisabled="isDisabled"
        />
      </FormItem>
      <Title :level="2" class="mb-16">Colors</Title>
      <div class="widgetViewSettingsModal__form__colors mb-32">
        <FormItem
          v-for="varName in sortedColors"
          rules="required"
          :key="varName"
        >
          <ColorInput
            v-model="innerValue.colors[varName]"
            :label="WIDGET_VIEW_COLORS_NAME_MAP[varName]"
            :isDisabled="isDisabled"
          />
        </FormItem>
      </div>
      <Checkbox
        v-model="innerValue.isUseAvailabilityColors"
        class="mb-32"
        :isDisabled="isDisabled"
      >
        Use color coding for availability
      </Checkbox>
      <div
        v-if="innerValue.isUseAvailabilityColors"
        class="widgetViewSettingsModal__form__colors mb-32"
      >
        <FormItem
          v-for="varName in sortedAvailabilityColors"
          rules="required"
          :key="varName"
        >
          <ColorInput
            v-model="innerValue.colors[varName]"
            :label="WIDGET_VIEW_AVAILABILITY_COLORS_NAME_MAP[varName]"
            :isDisabled="isDisabled"
          />
        </FormItem>
      </div>
      <div v-if="!isDisabled" class="widgetViewSettingsModal__form__actions">
        <Button is-outlined is-block @click="close"> Cancel </Button>
        <Button is-block :is-loading="isSubmitting" @click="handleSubmit">
          Save
        </Button>
      </div>
    </Form>
  </CustomModal>
</template>

<script>
import ColorInput from "@/components/common/ColorInput.vue";
import {
  WIDGET_VIEW_AVAILABILITY_COLORS_NAME_MAP,
  WIDGET_VIEW_COLORS_NAME_MAP,
} from "@/helpers/const";
import {
  WIDGET_VIEW_COLOR_PRESETS,
  WIDGET_VIEW_FONT_FAMILIES,
} from "@/helpers/mocks";
import WidgetViewPreset from "@/components/widget/WidgetViewPreset.vue";
import cloneDeep from "lodash.clonedeep";

export default {
  name: "WidgetViewSettingsModal",
  components: { WidgetViewPreset, ColorInput },
  props: {
    modalName: {
      type: String,
      default: "widgetViewSettingsModal",
    },
    value: {
      type: Object,
      required: true,
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      innerValue: {},
      WIDGET_VIEW_COLORS_NAME_MAP,
      WIDGET_VIEW_AVAILABILITY_COLORS_NAME_MAP,
      WIDGET_VIEW_COLOR_PRESETS,
      fontOptions: WIDGET_VIEW_FONT_FAMILIES.map((font) => ({
        value: font,
        name: font,
      })),
    };
  },
  computed: {
    sortedColors() {
      return Object.keys(WIDGET_VIEW_COLORS_NAME_MAP).filter(
        (varName) => !!this.innerValue.colors[varName]
      );
    },
    sortedAvailabilityColors() {
      return Object.keys(WIDGET_VIEW_AVAILABILITY_COLORS_NAME_MAP).filter(
        (varName) => !!this.innerValue.colors[varName]
      );
    },
  },
  methods: {
    close() {
      this.$modal.hide(this.modalName);
    },
    reset() {
      this.innerValue = cloneDeep(this.value);
    },
    handleSubmit() {
      this.$emit("submit", this.innerValue);
    },
    acceptPreset(preset) {
      this.innerValue.colors = { ...preset.colors };
    },
    isPresetActive(preset) {
      return Object.keys(this.innerValue.colors).every(
        (key) => this.innerValue.colors[key] === preset.colors[key]
      );
    },
    getPresetColors(preset) {
      const { primary, secondary } = preset.colors;
      const colors = Array.from({ length: 9 }).map((_, index) => ({
        color: primary,
        id: index + 1,
      }));
      colors.splice(4, 1, { color: secondary, id: preset.name });
      return colors;
    },
  },
};
</script>

<style lang="scss">
.widgetViewSettingsModal {
  padding: 40px 24px;
  overflow: visible;
  &.vm--modal {
    max-width: 800px !important;
    max-height: 95vh;
    overflow: auto;
  }
}
</style>
<style scoped lang="scss">
.widgetViewSettingsModal {
  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  &__title {
    text-align: center;
    margin-bottom: 24px;
  }

  &__form {
    &__colors {
      display: grid;
      grid-template-columns: 1fr;
      gap: 12px 24px;

      @media (min-width: $media-laptop) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
    }

    &__actions {
      display: flex;
      flex-direction: column;
      gap: 12px;
      flex-wrap: wrap;

      @media (min-width: $media-laptop) {
        flex-direction: row;
      }
    }
  }

  &__presets {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    &__item {
      padding: 3px;
      border: 3px solid transparent;
      transition: 0.3s;
      cursor: pointer;
      border-radius: 8px;

      &.m-active {
        border-color: $primary;
      }
      &.m-disabled {
        cursor: not-allowed;
      }
    }
  }
}
</style>
